

























import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents);

import InitializeScene from "@/src/Scene";

/** font-awesome stuff */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

Vue.component("font-awesome-icon", FontAwesomeIcon);

import { APPLICATION_STATE } from "@/types";

import { mapGetters,  mapMutations } from "vuex";

import Button from "@/components/Button.vue";
import DiscountFields from "@/components/DiscountFields.vue";
import TopText from "@/components/TopText.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";

export default Vue.extend({
  name: 'Main',
  data() {
    return {
      discountText: "Surprise",
      backText: "Save Discount Code",
      saved: false,
      promoCode: "NYMR10", 
      promoFirstRow: "Redeem 10% off your first Virtual Product Showcase app", 
      promosecondRow: "before 31 December 2022", 
    }
  },

  mounted()
  {
    InitializeScene();
  },
  components: { Button, DiscountFields, TopText, LoadingOverlay },
  methods: {
    saveToClipboard : function () : void {   
       /* Get the text field */
      const copyText = document.createElement("input");
      copyText.setAttribute("value", (this as any).promoCode);


      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);
      console.log(navigator.clipboard);
      (this as any).backText = "Saved to clipboard!";
      (this as any).saved = true;

    },
    ...mapMutations([
            "collectDiscount", "swipeMade", "doReset", "setApplicationState"
  ]),
  },
  computed: { 
    ...mapGetters([
            "IsDiscountCollected",
            "IsDiscountButtonShown", 
            "GetTopText",
            "ApplicationState",
    ]),
    isMobile: function() {
      return window.innerWidth < 600;
    },
    cssVars() {
      console.log(this.ApplicationState);
      return {
        '--left': this.ApplicationState === 'PROMO_COLLECTED' ? "43px" : "unset",
        '--right': this.ApplicationState === 'PROMO_COLLECTED' ? "unset" : "43px",
        
      }
    }
  }
});

<template>
    
        <div class="overlay" :style="cssVars">
             <font-awesome-icon id="icon"   :icon="'snowflake'" />
        </div>
    
</template>

<script>

import { mapGetters } from "vuex";

export default {
    props: ["display", "state", "loadingValue", "isLoading"],
    computed: { 
    ...mapGetters([
            "ApplicationState",
    ]),
    cssVars() {
        return {
            '--opacity': this.ApplicationState === "LOADING" ? 1 : 0,
        }
    }
}
}
</script>


<style scoped>
.vld-parent {
    z-index: 1000; 
}

#icon {
    color: #fff;
    width: 30px;
    height: 30px;
animation: pulse 2.2s infinite;
  animation-timing-function: ease-out;
}

@keyframes pulse {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.overlay {


    width: 100vw;
    height: 100vh;
    opacity: var(--opacity);
    transition: 900ms;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background: radial-gradient(54.31% 30.65% at 104.69% 104.67%, #EF4125 0%, rgba(239, 65, 37, 0) 100%), linear-gradient(
180deg, rgba(255, 255, 255, 0.31) 0%, rgba(255, 255, 255, 0) 30.42%), radial-gradient(41.42% 41.42% at 81.87% 28.31%, #F2AD22 0%, rgba(242, 173, 34, 0) 100%), radial-gradient(52.33% 52.33% at 16.1% 77.79%, rgba(242, 34, 89, 0.6) 0%, rgba(242, 34, 89, 0) 100%), #F36D21;
        
}

.vld-parent > div:first-child { 
    margin-top: 99px;
}
</style>



<style>
/* Hide the default loader animation   */
.vld-icon {
    opacity: 0;
}
</style>
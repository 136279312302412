//@ts-nocheck
import Vue from 'vue'
import Vuex from 'vuex'

import { APPLICATION_STATE } from "@/types";
console.log("store.ts", Vue)
Vue.use(Vuex);

const defaultState = {
  state: APPLICATION_STATE.LOADING,
  swipes: 0,
};

const copyDefaultState = () => JSON.parse(JSON.stringify(defaultState));

export default new Vuex.Store({
  state: copyDefaultState(),
  getters: {
    GetTopText: function ( state ) {
      if( state.swipes === 0 && state.state !== "LOADING" ) {
      return {
        mainRows: [  "Happy New Year from all of us at MRstudios!",
         "We had an exciting 2021 working on 56 projects in collaboration  with 7 manufacturers and welcoming 10 new members to our team."],
        smallerRows: [ "Keep swiping for a little surprice"],
      }}
      if( state.swipes === 1 ){ 
        return {
          mainRows: [ "As we enter the new year, we look forward to delivering rich 3D user experiences that help manufacturers worldwide improve their marketing, sales, and training processes.",],
          smallerRows: [ "Keep swiping for a little surprice"],
        }}
      if( state.swipes === 2 ){ 
        return {
          mainRows: [ "To show our gratitude for your support this year, we have prepared a little surprise for you!",],
          smallerRows: [ ],
        }}

        return { mainRows: [], smallerRows: []}
    },
    ApplicationState: function ( state ) : APPLICATION_STATE{
      return state.state
    }
  },
  mutations: {
    collectDiscount : function ( state ) {
        state.state = APPLICATION_STATE.PROMO_COLLECTED;
        const e = new CustomEvent('toggle-models', { detail: { visible: false } });
        document.dispatchEvent(e);
    },
    doReset: function ( state ){
        state.state = APPLICATION_STATE.READY_TO_PICKUP_PROMO;
        const event = new CustomEvent('toggle-models', { detail: { visible: true, reset: true } });
        document.dispatchEvent(event);
    },
    showDiscountButton: function( state ){
        state.showDiscountButton = true;
    },
    setApplicationState: function ( state, desiredState ){
        state.state = desiredState;
    },
    swipeMade: function ( state , direction ){


      if( state.state === APPLICATION_STATE.PROMO_COLLECTED
         || direction === "top" || direction === "bottom" ) return;
      state.swipes = direction === "left" ? state.swipes + 1 : state.swipes - 1 ;


      if( state.swipes === 3 ) state.swipes = 0;
      if( state.swipes < 0 ) state.swipes = 2;
      if( state.swipes === 0  ){ state.state = APPLICATION_STATE.INIT; }
      if( state.swipes === 1  ){ state.state = APPLICATION_STATE.KEEP_SWIPING; }
      if( state.swipes === 2  ){ state.state = APPLICATION_STATE.READY_TO_PICKUP_PROMO;  };

      //swipe
      const event = new CustomEvent('swipe', { detail: { direction } });
      document.dispatchEvent(event);
      console.log( state.state );
      
    },
    doneLoading: function ( state  ){
        console.warn("done");
        state.state = APPLICATION_STATE.INIT;
    }

  }
})
<template>
    <div class="container" :style="cssVars">
        <font-awesome-icon id="icon" @click="doReset"  :icon="'times'" />
        <div  class="promo-code">
            {{ this.promoCode }}
        </div>
        <div  class="first-row">
            {{ this.firstRow }}
        </div>
        <div  class="second-row">
            {{ this.secondRow }}
        </div>
        <div  class="third-row">
            Learn about the <a href="https://www.mrstudios.eu/virtual-product-showcase" target="_blank">app</a>
        </div>
        <a id="tc"
        href="https://www.mrstudios.eu/ny-discount-terms-and-conditions/"
        target="_blank"
        class="">Read full Terms & Conditions</a>
    </div>
</template>

<script>
    
    export default {
        props: ["state", "promoCode", "firstRow", "secondRow", "doReset"],

        computed: {
            cssVars() {
                const IsMobile = window.innerWidth < 600;
                return {
                    '--promo-font-size': IsMobile ? "54px" : "120px",
                    '--promo-line-height': IsMobile ? "60px" : "130px",
                    '--1-row-font-size': IsMobile ? "20px" : "48px",
                    '--1-row-line-height': IsMobile ? "25px" : "58px",
                    '--2-row-font-size': IsMobile ? "13px" : "30px",
                    '--2-row-line-height': IsMobile ? "28px" : "42px",
                    '--3-row-font-size': IsMobile ? "13px" : "24px",
                    '--3-row-line-height': IsMobile ? "28px" : "36px",
                    '--bottom-ts': IsMobile ? "30px" : "30px",
                    '--font-ts': IsMobile ? "12px" : "18px",
                    '--line-height-ts': IsMobile ? "20px" : "42px",
                    
                    '--opacity': this.state === "PROMO_COLLECTED" ? 1 : 0,
                }
            }
        },
    }
</script>

<style scoped>

.promo-code {
    cursor: text;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: var(--promo-font-size);
    line-height: var(--promo-line-height);

    text-align: center;
    letter-spacing: -0.04em;

    color: #000000;
    pointer-events: auto;
    user-select: text;
 }

.first-row { 
    font-family: Open Sans;
font-style: normal;
font-weight: 800;
font-size: var(--1-row-font-size);
line-height: var(--1-row-line-height);
/* or 121% */

text-align: center;
letter-spacing: -0.05em;

/* White */

color: #FFFFFF;
}

#tc {
    cursor: pointer;
    pointer-events: auto;
    text-decoration: none;
    position: absolute;
    bottom: var(--bottom-ts);
    font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: var(--font-ts);
line-height: var(--line-height-ts);
/* identical to box height, or 233% */

text-align: center;

/* MR/Brand/Orange */

color: #F36D21;
}

#icon {
    height: 29px;
    position: absolute;
    top: 20px;
    right: 25px;
    pointer-events: auto;
    cursor: pointer;
}

.second-row { 
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: var(--2-row-font-size);
    line-height: var(--2-row-line-height);
    /* identical to box height, or 140% */

    text-align: center;

    /* MR/Brand/White */

    color: #FFFFFF;
}

a { color: #FFFFFF; }

.third-row {
    text-align: center;
    color: #FFFFFF;
    font-size: var(--3-row-font-size);
    line-height: var(--3-row-line-height);
}

.container { 
    opacity: var(--opacity);
    transition: 1.1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    font-family: 'Open Sans', sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    
}

    
</style>
//@ts-nocheck
import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module.js'
import CameraControls from 'camera-controls';
import TWEEN from '@tweenjs/tween.js';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as C from "./constants";
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

import store from "@/store";

/** models */

import kirsten from '../../../public/assets/models/characters/kirsten.glb';
import thomas from '../../../public/assets/models/characters/thomas.glb';
import morten from '../../../public/assets/models/characters/morten.glb';


import church from '../../../public/assets/models/buildings/church.glb';
import dancinghouse from '../../../public/assets/models/buildings/dancing_house.glb';
import powdertower from '../../../public/assets/models/buildings/powder_tower.glb';

let modelsLoaded = 0;
const modelsCount = 6;

const scaleConf = 0.7;
const xConf = -6;
const zConf = -5;

const buildingsAlignment = [ 
	{ model: church, position: [ xConf + (5 * scaleConf) ,-42, zConf + (25 * scaleConf)], scale: 200 * scaleConf, rotation : [ 0, THREE.MathUtils.degToRad(40),0 ] },
	{ model: dancinghouse, position: [ xConf + (15 * scaleConf),-42, zConf + (-12 * scaleConf)], scale: 140 * scaleConf, rotation : [ 0, THREE.MathUtils.degToRad(-105),0 ] },
	{ model: powdertower, position: [ xConf + (-20 * scaleConf),-42,  zConf + (6 * scaleConf)], scale: 150 * scaleConf, rotation : [ 0, THREE.MathUtils.degToRad(100),0 ] },
]

/** textures */
import snowflake1 from'../../../public/assets/textures/snowflake1.png';
import snowflake2 from'../../../public/assets/textures/snowflake2.png';
import snowflake3 from'../../../public/assets/textures/snowflake3.png';
import snowflake4 from'../../../public/assets/textures/snowflake4.png';
import snowflake5 from'../../../public/assets/textures/snowflake5.png';

import hdr from "../../../public/assets/textures/hdr.hdr";

/** scene variables */

CameraControls.install( { THREE: THREE } );

let container; let clock = new THREE.Clock();
let stats: Stats | null = null;
let camera: THREE.Camera | null = null;
let scene: THREE.Scene | null = null;
let renderer: THREE.Renderer | null = null;

let cameraControls: CameraControls | null = null;

let textureLoader = new THREE.TextureLoader();

let mixers = {}; // All the THREE.AnimationMixer objects for all the animations in the scene


let mouseX = 0, mouseY = 0, savedX = 0;
let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;

// animations
let activeTweens : Array<TWEEN>  = [];
let sceneIsRotating : boolean = false;

let loader = new GLTFLoader(  );

const snowFlakesMaterials = [];
let snowFlakeParameters = [];

/* swiping logic variables */
let currentCharacter = 0;
const charactersOrder = [
	{ path: kirsten, name: "kirsten" },
	{ path: morten, name: "morten" },
	{ path: thomas, name: "thomas" },
];
let swipes = 0;

function getCharacter (i) {
	const { length } = charactersOrder;
	return charactersOrder[(i % length + length) % length].name;
}

const togglableModels = [];


export function init(CanvasContainer: HTMLElement) 
{
	container = CanvasContainer;
	
	renderer = new THREE.WebGLRenderer( { antialias: true,  alpha: true  } );
	renderer.setClearColor( 0x000000, 0 );
	renderer.setPixelRatio( window.devicePixelRatio );
	renderer.setSize( window.innerWidth, window.innerHeight );
	// Tone mapping init
	renderer.toneMapping = THREE.ACESFilmicToneMapping;
	renderer.toneMappingExposure = 2;
	renderer.outputEncoding = THREE.LinearEncoding ;

	camera = new THREE.PerspectiveCamera( 20, window.innerWidth / window.innerHeight, 1, 10000 );
	camera.position.x = C.CAMERA_INIT_POSITION.x;
	camera.position.y = C.CAMERA_INIT_POSITION.y;
	camera.position.z = C.CAMERA_INIT_POSITION.z;
	cameraControls = new CameraControls( camera, renderer.domElement );
	// cameraControls.mouseButtons.left = CameraControls.ACTION.OFFSET ;
	cameraControls.enabled = false;
	scene = new THREE.Scene();
	

	// let backgroundTexture = textureLoader.load( bg );
    // scene.background = backgroundTexture;
	

	/** lights */

	const intensity = 0.8;
	const light1 = new THREE.AmbientLight(0xFFFFFF, intensity);
	scene.add(light1);

	[ 
		{ x: 50, y: -30, z: 50, intensity: 0.5  },
		{ x: 50, y: -30, z: -50, intensity: 0.5 },
		{ x: -50, y: -30, z: -50, intensity: 0.5 },
		{ x: -50, y: -30, z: 50, intensity: 0.5  },
	
	].forEach(({intensity, ...pos}) => {
		const color = 0xffa200;
		
		const l = new THREE.PointLight( color, intensity, 1000 );
		l.position.set( pos.x, pos.y, pos.z );
		scene.add( l );

	})

	/** fog */

	const fogColor = 0xf36e26;
	scene.fog = new THREE.Fog( fogColor, 300, 490);

	/** hdr */

	new RGBELoader().load(hdr, (texture) => 
	{
		texture.mapping = THREE.EquirectangularReflectionMapping;
		scene.environment = texture;
	});

	/**---------------------------------------------------------------- */

	let loadedBuildings = [];
	
	buildingsAlignment.forEach( ({ model, position, rotation, scale }) => {
		loader.load( model , function ( gltf ) {	

				
			
			    const mesh = gltf.scene.children[0];
				mesh.position.set(...position);
				mesh.scale.set(scale, scale, scale);
				mesh.rotation.set(...rotation);
				scene.add( mesh );
				togglableModels.push( mesh );
				loadedBuildings.push( mesh );

				rotateAboutPoint( 
					mesh,
					new THREE.Vector3( 0,0,0 ),
					new THREE.Vector3( 0,1,0 ),
					THREE.MathUtils.degToRad( 170), true
					);
				
				modelsLoaded++;
				if ( modelsLoaded === modelsCount ) doneLoading();
				
				
		});
	} );



	


	let loaded = 0, animated = [];
	
	charactersOrder.forEach(({ path, name }, i, arr) => {
			loader.load( path , function ( gltf ) {	
				
				gltf.scene.children[0].position.x = 55;
				gltf.scene.children[0].position.y = -40;
				gltf.scene.children[0].position.z = 0;
				
				gltf.scene.children[0].scale.x = C.CHARACTER_SCALE;
				gltf.scene.children[0].scale.y = C.CHARACTER_SCALE;
				gltf.scene.children[0].scale.z = C.CHARACTER_SCALE;

				gltf.scene.children[0].rotation.y = Math.PI / 2;
				const rotateCircle = THREE.MathUtils.degToRad(30) + (i - 1) * (2 * Math.PI / arr.length );

				rotateAboutPoint( 
					gltf.scene.children[0],
					new THREE.Vector3( 0,0,0 ),
					new THREE.Vector3( 0,1,0 ),
					rotateCircle, true
					);

				scene.add( gltf.scene  );
				togglableModels.push( gltf.scene );

				

				++loaded; 
				animated.push( { ...gltf, name });

				if ( loaded === arr.length){
						initAnimations( animated );
				}
				
				modelsLoaded++;
				console.log(modelsLoaded);
				if ( modelsLoaded === modelsCount ) doneLoading();
			
			} );
		})


	const cgeometry = new THREE.CylinderGeometry( 75, 75, 20, 92 );
	const cmaterial = new THREE.MeshBasicMaterial( {color: 0xfff5b8} );
	const cylinder = new THREE.Mesh( cgeometry, cmaterial );
	cylinder.position.y = -50;
	scene.add( cylinder );

	addSnow();

    container.appendChild( renderer.domElement );
	renderer.domElement.id = C.THREE_CANVAS_ID;

	// stats = new Stats();
	// stats.domElement.style.cssText = 'position:absolute; top:0px; right:0px;';
	// container.appendChild( stats.dom );

	// document.addEventListener( 'mousemove', onDocumentMouseMove );
	// document.addEventListener( 'touchmove', onDocumentMouseMove );
	// document.addEventListener( 'mouseup', onDocumentMouseUp );
	// document.addEventListener( 'touchend', onDocumentMouseUp );
	// document.addEventListener( 'mousedown', onDocumentMouseDown );
	// document.addEventListener( 'touchstart', onDocumentMouseDown );
	//
	window.addEventListener( 'resize', onWindowResize );
	// custom events
	document.addEventListener('toggle-models', onToggleModels );
	document.addEventListener('swipe', onSwipeMade );
}

function doneLoading(){

	store.commit("doneLoading");

}

function addSnow(){

	const geometry = new THREE.BufferGeometry();
	const vertices = [];

	const sprite1 = textureLoader.load( snowflake1 );
	const sprite2 = textureLoader.load( snowflake2 );
	const sprite3 = textureLoader.load( snowflake3 );
	const sprite4 = textureLoader.load( snowflake4 );
	const sprite5 = textureLoader.load( snowflake5 );


	snowFlakeParameters = [
		
		[[ 0.90, 0.05, 0.5 ], sprite1, 10 / 4 ],
		[[ 1.0, 0.2, 0.5 ], sprite2, 20 / 4 ],
		[[ 0.95, 0.1, 0.5 ], sprite3, 15 / 4 ],
		[[ 0.85, 0, 0.5 ], sprite5, 8 / 4 ],
		[[ 0.80, 0, 0.5 ], sprite4, 5 / 4 ]
	
	]

	for ( let i = 0; i < 220; i ++ ) {

		const x = randomRange(150, -150) ;
		const y = randomRange(150, -80) ;
		const z = randomRange(150, -150) ;
		vertices.push( x, y, z );
	}
	geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

	for ( let i = 0; i < snowFlakeParameters.length; i ++ ) {

		const color = snowFlakeParameters[ i ][ 0 ];
		const sprite = snowFlakeParameters[ i ][ 1 ];
		const size = snowFlakeParameters[ i ][ 2 ];

		snowFlakesMaterials[ i ] = new THREE.PointsMaterial( { 
			size: size, map: sprite1, blending: THREE.AdditiveBlending, depthTest: false, transparent: true
		} );
		 snowFlakesMaterials[ i ].color.setHSL( color[ 0 ], color[ 1 ], color[ 2 ] );

		const particles = new THREE.Points( geometry, snowFlakesMaterials[ i ] );

		particles.rotation.x = Math.PI ;
		particles.rotation.y = Math.PI ;
		particles.rotation.z = Math.PI ;

		
		scene.add( particles );
}



		

}

function initAnimations( gltfs ) {
	
	gltfs.forEach ( ({ scene, animations, name  }) => {
		const mixer = new THREE.AnimationMixer( scene );
		const animationType = getCharacter(currentCharacter) === name ? "action" : "idle"
		const clip = THREE.AnimationClip.findByName( animations, animationType );
		const action = mixer.clipAction( clip ); 
		action.play();
		mixers[name] = { mixer, animations };
		mixers[name].actions = { [animationType]: action };
	} );
	
}

// obj - your object (THREE.Object3D or derived)
// point - the point of rotation (THREE.Vector3)
// axis - the axis of rotation (normalized THREE.Vector3)
// theta - radian value of rotation
function rotateAboutPoint(obj, point, axis, theta, pointIsWorld){
    pointIsWorld = (pointIsWorld === undefined)? false : pointIsWorld;

    if(pointIsWorld){
        obj.parent.localToWorld(obj.position); // compensate for world coordinate
    }

    obj.position.sub(point); // remove the offset
    obj.position.applyAxisAngle(axis, theta); // rotate the POSITION
    obj.position.add(point); // re-add the offset

    if(pointIsWorld){
        obj.parent.worldToLocal(obj.position); // undo world coordinates compensation
    }

    obj.rotateOnAxis(axis, theta); // rotate the OBJECT
}

function onWindowResize() {
	windowHalfX = window.innerWidth / 2;
	windowHalfY = window.innerHeight / 2;
	camera.aspect = window.innerWidth / window.innerHeight;
	camera.updateProjectionMatrix();
	renderer.setSize( window.innerWidth, window.innerHeight );
}
function onDocumentMouseMove( event ) {
	mouseX = ( event.clientX - windowHalfX );
	mouseY = ( event.clientY - windowHalfY );
}

function onDocumentMouseDown( event ) {
	savedX = mouseX;
}

function onDocumentMouseUp( event ) {
	console.log( camera.position ); 

	if ( !event.target.id || event.target.id != C.THREE_CANVAS_ID  ) return;
	
	if( sceneIsRotating  ) return;
	sceneIsRotating = true;
	swipes++;
	if ( mouseX >= 0 ) { 
		currentCharacter++;
		updateMixers( -1 );
		rotate( C.SEGMENT_DEGREE );
	} else { 
		currentCharacter--;
		updateMixers( 1 );
		rotate( -1 * C.SEGMENT_DEGREE );
	}

	
	setTimeout(() => { 
		store.commit("swipeMade", { swipes });
		if ( swipes === charactersOrder.length - 1 ) swipes = -1;
	 }, 1000);
	
}

function onToggleModels( event ){
	const { visible,  } = event.detail;

	togglableModels.forEach( (model) => { model.visible = visible });
}

function onSwipeMade ( event ) {
	const { direction } = event.detail;
	if ( direction === "left") { 
		currentCharacter++;
		updateMixers( -1 );
		rotate( C.SEGMENT_DEGREE );
	} else { 
		currentCharacter--;
		updateMixers( 1 );
		rotate( -1 * C.SEGMENT_DEGREE );
	}
}

function updateMixers( direction ){
	const curr = getCharacter(currentCharacter);
	for( let name of Object.keys( mixers)){
		const { mixer, animations, actions } = mixers[name];
		// get idle clip 
		const idleClip = actions.idle 
		||  mixer.clipAction( THREE.AnimationClip.findByName( animations, 'idle' ) ); 
		// get action clip
		const actionClip = actions.action 
		||  mixer.clipAction( THREE.AnimationClip.findByName( animations, 'action' ) ); 
		

		if( name === curr) {
			idleClip.fadeOut(0.5);
			actionClip.reset();
			actionClip.fadeIn(0.5);
			actionClip.play();
			actionClip.enabled = true;
		}

		if(name === getCharacter(currentCharacter + direction)) {
			actionClip.fadeOut(0.5);
			idleClip.fadeIn(0.5);
			idleClip.play();
			idleClip.enabled = true;
		}

		mixers[name].actions = { idle: idleClip, action: actionClip }
}
}



function rotate ( deg = 90, duration = 1000, easing = TWEEN.Easing.Quadratic.Out ) {
	// save the start angle
	let startAzimuthAngle = cameraControls.azimuthAngle;
	const t = new TWEEN.Tween(cameraControls)
	  .to(
		{ azimuthAngle: deg * THREE.MathUtils.DEG2RAD + startAzimuthAngle },
		duration
	  )
	  .easing(easing)
	  .onStart(function () {
		
		// disable user control while the animation
		cameraControls.enabled = false;

	  })
	  .onComplete(function () {
		cameraControls.enabled = false;
		sceneIsRotating = false;
	  })
	  .start();

	activeTweens = [ t ];
};

//
export function animate() {

	for( const tween of activeTweens ){
		tween.update();
	}

	TWEEN.update();
	requestAnimationFrame( animate );
	render();
	// stats.update();
}

function render() {

	const time = Date.now() * 0.00004;

	const delta = clock.getDelta();
	cameraControls.update( delta );
	camera.lookAt( scene.position );

	if( Object.keys( mixers ).length > 0 ) {

		for(let [ name, { mixer } ] of Object.entries( mixers ) ) {
			mixer.update( delta );
		}
	}

	for ( let i = 0; i < scene.children.length; i ++ ) {

		const object = scene.children[ i ];

		if ( object instanceof THREE.Points ) {
			
			let vertices = object.geometry.attributes.position.array;
			for( let j = 1; j < vertices.length; j+=3) {
				vertices[ j ] = vertices[ j ] - 0.05;
				if( vertices[ j ] < -50 ) vertices[ j ] = randomRange(150, 70)
			}
			object.geometry.attributes.position.needsUpdate = true;

		}

	}



	renderer.render( scene, camera );
}

function randomRange(min, max) {
    return ((Math.random() * (max - min)) + min);
}
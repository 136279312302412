<template>
    <div class="container" :style="cssVars">
        <div class="rows-listed">
        <div v-for="(row, key) in topText.mainRows" :key="key">
		 <div class="row" >{{ row }}</div>
  	    </div>
         
          <div v-for="(row, key) in topText.smallerRows" :key="key">
		 <div class="smaller-row">{{ row }}</div>
  	    </div>
           </div>
        
    </div>
</template>

<script>
    
    export default {
        props: ["topText"],

        computed: {
            cssVars() {
                const IsMobile = window.innerWidth < 600;
                return {
                    '--row-font-size': IsMobile ? "15px" : "32px",
                    '--row-line-height': IsMobile ? "20px" : "38px",
                    '--smaller-row-font-size': IsMobile ? "12px" : "18px",
                    '--smaller-row-line-height': IsMobile ? "24px" : "32px",

                    '--dim': this.dimensions || "24px",
                    '--bg': this.withBackground ? `linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #000000` : "none",
                    '--box-shadow': this.withBackground ? `0px 2px 12px rgba(0, 0, 0, 0.2)` : `unset`,
                    '--border': this.withBackground ? `2px solid #4A5D6D` : `unset`
                }
            }
        },
    }
</script>

<style scoped>

.rows-listed {
     
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    
    max-width: 80vw;
    text-align: left;
    width: 100%;
}
     

.row { 
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: var(--row-font-size);
    line-height: var(--row-line-height);
    letter-spacing: -0.04em;
    color: #FFFFFF;
}

.smaller-row {

    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: var(--smaller-row-font-size);
    line-height: var(--smaller-row-line-height);
    color: #FFFFFF;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    width: 100%;
    
}


.container { 
    padding: 0 10px;
   
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    position: fixed;
    top: 10.5vh;
    width: 100vw;
    
    
    
}

    
</style>
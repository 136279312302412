<template>
<div class="btn-box" :style="cssVars">
    <div class="container" >
        <div  class="button" @click="callback" :style="cssVars">
        {{ this.text }}
    </div>
    </div>
</div>
</template>

<script>
    
    export default {
        props: [ "callback", "text", "black"],

        methods: {
          clicked: function () {  this.callback() },
        },
        computed: {
            cssVars() {
                const mobile = window.innerWidth < 600;
                return {
                    "--bottom": mobile ? "37px" : "70px",
            
                    '--bg': this.black ? "black" : "#F36D21"

                }
                }
        },
    }
</script>

<style scoped>

.btn-box {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}
.container {
    position: absolute;
    bottom: var(--bottom);
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    
}

.button {

    
    color: #FFFFFF;
    display: flex;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    flex-direction: column;
    justify-content: center;

    text-align: center;
    padding: 9px 28px;

    min-width: 118px;
    height: 47px;

    /* MR/Brand/Orange */

    background: var(--bg);
    border-radius: 3px;

    pointer-events: auto;
    cursor: pointer;
}
    
</style>